<template>
  <b-card>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="success"
      class="mb-1"
      @click="handleDownload"
    >
      下载Excel
    </b-button>
    <div
      v-if="isLoading"
      class="d-flex justify-content-center align-items-center"
    >
      <b-spinner
        type="grow"
        variant="warning"
        class="mr-1"
      />
      <span class="loading-text">Loading...</span>
    </div>
    <b-table
      v-else
      responsive
      bordered
      :items="list"
      :fields="fields"
    >
      <template #cell(shipment_id)="data">
        <span class="mw-100">{{ data.item.shipment_id }}</span>
      </template>
      <template #cell(client_id)="data">
        <span class="mw-100">{{ data.item.client_id }}</span>
      </template>
      <template #cell(recipient)="data">
        <span class="mw-200">{{ data.item.recipient }}</span>
      </template>
      <template #cell(package_amount)="data">
        <span class="mw-70">{{ data.item.package_amount }}</span>
      </template>
      <template #cell(shipment_status)="data">
        <span class="mw-220">{{ data.item.shipment_status }}</span>
      </template>
      <template #cell(arrive_time)="data">
        <span class="mw-200">{{ data.item.arrive_time }}</span>
      </template>
      <template #cell(total_chargeable_weight)="data">
        <span class="mw-140">{{ Number(data.item.total_chargeable_weight).toFixed(2) }}</span>
      </template>
      <template #cell(price)="data">
        <span class="mw-70">AU${{ Number(data.item.price).toFixed(2) }}</span>
      </template>
      <template #cell(is_belong)="data">
        <span class="mw-140">{{ data.item.is_belong ? '是' : '否' }}</span>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BTable, BCard,
  BSpinner,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BTable,
    BCard,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      list: [],
      isLoading: false,
      fields: [
        { key: 'shipment_id', label: '运单号' },
        { key: 'client_id', label: '客户ID' },
        { key: 'recipient', label: '收件人' },
        { key: 'package_amount', label: '包裹数' },
        { key: 'shipment_status', label: '运单状态' },
        { key: 'arrive_time', label: '到达自提点时间' },
        { key: 'total_chargeable_weight', label: '计费重量(Kg)' },
        { key: 'price', label: '预计营收' },
        { key: 'is_belong', label: '是否自提点运单' },
      ],
    }
  },
  created() {
    this.handleInit()
  },
  methods: {
    async handleDownload() {
      try {
        const params = {
          self_agent_name: this.$route.query.self_agent_name,
          self_agent_id: this.$route.params.id,
          year_month: this.$route.query.year_month,
        }
        this.$store.dispatch('shipment/handleAdminExport', params)
      } catch (error) {
        console.log('error', error)
      }
    },
    async handleInit() {
      try {
        this.isLoading = true
        const { data } = await this.$api.statisticSelfAgentByYearmonth({
          self_agent_id: this.$route.params.id,
          year_month: this.$route.query.year_month,
        })
        this.isLoading = false
        this.list = data.statistics_info
      } catch (error) {
        console.log('error', error)
      }
    },
    async handleConfirm(item) {
      try {
        const data = await this.$api.confirmPickup({ shipment_id: item.shipment_id })
        if (data.code === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: '变更状态成功',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          })
        }
      } catch (error) {
        console.log('error', error)
      }
    },
  },
}
</script>

  <style lang="scss" scoped>
  .text{
      font-size:18px
  }
  .img{
    width: 60px;
    height: 60px;
  }
  .watch{
    cursor: pointer;
    font-size: 18px;
    display: block;
    min-width: 90px;
    color: #FFAF2A;
  }
  .mw-220{
    display: block;
    min-width: 220px
  }
  .mw-200{
    display: block;
    min-width: 200px
  }
  .mw-140{
    display: block;
    min-width: 140px;
  }
  .mw-100{
    display: block;
    min-width: 100px;
  }
  .mw-70{
    display: block;
    min-width: 80px;
  }
  .mw-50{
    min-width: 50px;
  }
  .loading-text{
    font-size: 26px;
  }
  </style>
